import { GET_HISTORY } from "./actionTypes";
import { instance } from "./instance";

export const getHistory = () => {
  return async (dispatch) => {
    try {
      const response = await instance.get("history");
      dispatch({
        type: GET_HISTORY,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};
