import React from "react";
import MenuItem from "./MenuItem";
import Category from "./Category";
import { connect } from "react-redux";

const MenuCategory = ({ categories }) => {
  const categoriesList = categories.map((category) => (
    <Category category={category} key={category.nameEn} />
  ));

  const categoryTabList = categories.map((category) => (
    <MenuItem category={category} key={category.nameEn} />
  ));

  return (
    <>
      <ul className="nav site-tab-nav menu-nav" id="pills-tab" role="tablist">
        {categoriesList}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {categoryTabList}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categoryState.categories,
});

export default connect(mapStateToProps)(MenuCategory);
