import React from "react";
import {connect} from "react-redux"
import Header from "./Header";

const Cover = ({lang}) => {
  const menuScroll = () => {
    let elmnt = document.getElementById("menu");
    elmnt.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="cover_1 overlay bg-light">
      <div
        className="img_bg"
        
        data-stellar-background-ratio="0.5"
      >
      <Header/>
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-10" data-aos="fade-up">
              <h2 className="heading mb-5" style={{color:"#fffce5"}}>
              {lang === "En" ? "Welcome to Greater Amman Restaurants" :"أهلاً بك في مطاعم عمّان الكبرى"}
                
              </h2>
              <button
                onClick={() => menuScroll()}
                className="smoothscroll btn btn-outline-white px-5 py-3 "
                style={{backgroundColor:"#66000099",color:"#fffce5",borderColor:"#fffce5",letterSpacing:"inherit"}}
              >
                {lang === "En" ? "Go to Menu" :"قائمة الطعام"}
                
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

export default connect (mapStateToProps) (Cover);
