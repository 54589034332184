import React, { useState } from "react";
import {connect} from "react-redux"
import logo from "../../assets/images/logo1.png";
import { Link } from "react-router-dom";
import {setLanguage} from "../../redux/actions"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const Header = ({lang ,setLanguage}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const menuScroll = () => {
    let elmnt = document.getElementById("menu");
    elmnt.scrollIntoView({ behavior: "smooth" });
  };

  const AboutScroll = () => {
    let elmnt = document.getElementById("about");
    elmnt.scrollIntoView({ behavior: "smooth" });
  };

  const locationScroll = () => {
    let elmnt = document.getElementById("location");
    elmnt.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar dark expand="md" className="navbar " style={{backgroundColor:"transparent" , textDecoration:"none"}} >
        <Link
                to="/home"
                style={{
                  display: "block",
                  padding: ".5rem 1rem",textDecoration:"none"
                }}
              >
              <div>{lang === "En" ? (<img src={logo} className="logo-nav" alt="logo"  />):(<img src={logo} className="logo-nav" alt="logo"  />)}</div> 
          </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar> 
          <Nav className={lang==="En"?"ml-auto":"mr-auto"} navbar >
            <NavItem >
            <Link
                to="/home"
                style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
              >
              {lang === "En" ? "Home" :"الرئيسية"}
                
              </Link>
            </NavItem>
            <NavItem
              onClick={() => AboutScroll()}
            >
              <NavLink style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}>
                {lang === "En" ? "About Us" :"عن المطعم"}
                
                </NavLink>
            </NavItem>
            <NavItem onClick={() => menuScroll()} style={{ cursor: "pointer" }}>
              <NavLink style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}>
                {lang === "En" ? "Menu" :"قائمة الطعام"}
                
                </NavLink>
            </NavItem>
            <NavItem>
              <Link
                to="/franchise"
                style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
              >
              {lang === "En" ? " We Franchise" :"دعم الفروع"}
               
              </Link>
            </NavItem>
            <NavItem onClick={() => locationScroll()}>
            <Link
                to="/location"
                style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
              >
              {lang === "En" ? "Our Locations" :"فروعنا"}
                
              </Link>
            </NavItem>
            {lang === "En" ? (
              <NavItem style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
                onClick = {()=>setLanguage("Ar")}
                >
              العربية
            </NavItem>) : (
              <NavItem style={{
                  color: "#fffce5	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
                onClick = {()=>setLanguage("En")}
                >
              English
            </NavItem>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

const mapDispatchToProps = (dispatch) =>{
  return{
    setLanguage : (langauage) => dispatch(setLanguage(langauage))
  }
}
export default connect(mapStateToProps , mapDispatchToProps)(Header);
