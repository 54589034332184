import React from 'react'
import {connect} from "react-redux"
import rest1 from "../../assets/images/1.jpg"
import rest2 from "../../assets/images/2.png"
import rest3 from "../../assets/images/3.jpg"
import {
  Card, CardImg, CardDeck, CardBody} from 'reactstrap';


const Locations = ({lang}) => {
    const go = lang==="En" ? "Go To Location" : "الذهاب الى الموقع"
    return (
      <>
      <div className="section bg-light" id="location">
      <div className="container">
      <div className="row section-heading justify-content-center mb-5"> 
      <div className="col-md-8 text-center">
        {lang === "En" ? (<h2 className="heading mb-3">Our Locations</h2>):(<h2 className="heading mb-3">مواقعنا</h2>)}
        {lang === "En" ? (<p className="sub-heading mb-5">You are welcome to visit us in any of our branches </p>):(<p className="sub-heading mb-5">استمتع بخدماتنا المتنوعة</p>)}
         
        </div>
      </div>
      <div className="row">

      <CardDeck>
      <div className="col-md-4 mb-5" data-aos="fade-up">
      <div className="media feature-icon d-block text-center">
      <Card style={{border:"none",backgroundColor:"transparent"}}>
        <CardImg top width="100%"  src={rest1} alt="rest1" className="rounded restLocation" />
        <CardBody>
          <a className="btn button" target="blank" href="https://www.google.com/maps/place/Amman+alkubra+Restaurants+-+%D9%81%D8%B1%D8%B9+%D8%A7%D9%84%D8%AE%D8%A7%D9%85%D8%B3%E2%80%AD/@31.9590064,35.8559036,15z/data=!4m8!1m2!2m1!1zQW1tYW4gYWwta3VicmEgUmVzdGF1cmFudHMg2KfZhNiu2KfZhdiz!3m4!1s0x151ca055f7dc3051:0xa30318e41fbc0f54!8m2!3d31.9607269!4d35.8763158" style={{letterSpacing:"inherit",backgroundColor:"#660000",color:"#fffce5",transition:"all 0.5s",marginTop:"5px",border:"none"}}>
          <span>{go}</span></a>
        </CardBody>
      </Card>
      </div>
      </div>
      <div className="col-md-4 mb-5" data-aos="fade-up" data-aos-delay="100">
     <div className="media feature-icon d-block text-center">
      <Card style={{border:"none",backgroundColor:"transparent"}}>
        <CardImg top width="100%"  src={rest2} alt="rest2" className="rounded restLocation" />
        <CardBody>
          <a className="btn button" target="blank" href="https://www.google.com/maps/place/Amman+al-kubra+Restaurants+-+%D9%81%D8%B1%D8%B9+%D8%A7%D9%84%D8%B3%D8%A7%D8%A8%D8%B9%E2%80%AD/@31.95725,35.853001,15z/data=!4m5!3m4!1s0x0:0x595b7479f7713028!8m2!3d31.95725!4d35.853001" style={{letterSpacing:"inherit",backgroundColor:"#660000",color:"#fffce5",transition:"all 0.5s",marginTop:"5px",border:"none"}}>
          <span>{go}</span></a>
        </CardBody>
      </Card>
</div>
</div>
 <div className="col-md-4 mb-5" data-aos="fade-up" data-aos-delay="300">
    <div className="media feature-icon d-block text-center">
      <Card style={{border:"none",backgroundColor:"transparent"}}>
        <CardImg top width="100%"  src={rest3} alt="rest3" className="rounded restLocation" />
        <CardBody>
          <a className="btn button" target="blank" href="https://www.google.com/maps/place/Amman+Alkubra+Restaurants+%D9%85%D8%B7%D8%A7%D8%B9%D9%85+%D8%B9%D9%85%D8%A7%D9%86+%D8%A7%D9%84%D9%83%D8%A8%D8%B1%D9%89+-+%D9%81%D8%B1%D8%B9+%D8%A7%D9%84%D9%85%D9%82%D8%A7%D8%A8%D9%84%D9%8A%D9%86%E2%80%AD/@31.9152186,35.9132747,19.96z/data=!4m13!1m7!3m6!1s0x0:0x0!2zMzHCsDU0JzU0LjciTiAzNcKwNTQnNDkuMSJF!3b1!8m2!3d31.915184!4d35.9136276!3m4!1s0x151ca105d1a76d6f:0xd3b8a1d76d8a4534!8m2!3d31.9150496!4d35.9135751?hl=en" style={{letterSpacing:"inherit",backgroundColor:"#660000",color:"#fffce5",transition:"all 0.5s",marginTop:"5px",border:"none"}}>
          <span>{go}</span></a>
        </CardBody>
      </Card>
</div>
</div>
    </CardDeck>
    </div>
    </div>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    lang: state.languageState.lang,
  });
  
  export default connect(mapStateToProps) (Locations)




   