import React from "react";
import { connect } from "react-redux";

const Category = ({ category,lang }) => {
  var test = "";
  if (category.nameEn === "Salads") {
    test = "nav-link active";
  } else {
    test = "nav-link";
  }
  return (
    <li className="nav-item">
      <a
        className={test}
        id={`${category.nameEn.toLowerCase().replace(/\s+/g, "")}-tab`}
        data-toggle="pill"
        href={`#${category.nameEn.toLowerCase().replace(/\s+/g, "")}`}
        role="tab"
        aria-controls={`${category.nameEn.toLowerCase().replace(/\s+/g, "")}`}
        aria-selected="true"
        style={{letterSpacing:"inherit"}}
      >
        {lang==="En"?category.nameEn:category.nameAr}
      </a>
    </li>
  );
};
const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});
export default connect(mapStateToProps)(Category);
