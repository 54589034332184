import React, { useState } from "react";
import {connect} from "react-redux"
import logo from "../../assets/images/logo1.png";
import { Link } from "react-router-dom";
import {setLanguage} from "../../redux/actions"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

const Fheader = ({lang ,setLanguage}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar dark expand="md" className="navbar " style={{backgroundColor:"transparent" , textDecoration:"none"}} >
        <Link
                to="/home"
                style={{
                  display: "block",
                  padding: ".5rem 1rem",textDecoration:"none"
                }}
              >
              <div>{lang === "En" ? (<img src={logo} className="logo-nav" alt="logo"  />):(<img src={logo} className="logo-nav" alt="logo"  />)}</div> 
          </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar> 
          <Nav className={lang==="En"?"ml-auto":"mr-auto"} navbar >
            <NavItem >
            <Link
                to="/home"
                style={{
                  color: "rgb(102, 0, 0)",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
              >
              {lang === "En" ? "Home" :"الرئيسية"}
                
              </Link>
            </NavItem>
            {lang === "En" ? (
              <NavItem style={{
                  color: "rgb(102, 0, 0)",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
                onClick = {()=>setLanguage("Ar")}
                >
              العربية
            </NavItem>) : (
              <NavItem style={{
                  color: "rgb(102, 0, 0)	",
                  fontSize:"18px",
                  display: "block",
                  padding: ".5rem 1rem",
                  textDecoration: "none",
                  cursor:"pointer"
                }}
                onClick = {()=>setLanguage("En")}
                >
              English
            </NavItem>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

const mapDispatchToProps = (dispatch) =>{
  return{
    setLanguage : (langauage) => dispatch(setLanguage(langauage))
  }
}
export default connect(mapStateToProps , mapDispatchToProps)(Fheader);
