import React from "react";
import dinner from "../../assets/images/dinnerr.png";
import delivery from "../../assets/images/delivery.png";
import catering from "../../assets/images/catering.png";
import {connect} from "react-redux"

const Services = ({lang}) => {
  return (
    <div className="section bg-white services-section" data-aos="fade-up">
      <div className="container">
        <div className="row section-heading justify-content-center mb-5">
          <div className="col-md-8 text-center">
          {lang === "En" ? (<h2 className="heading mb-3">Our Services</h2>):(<h2 className="heading mb-3">خدماتنا</h2>)}
          {lang === "En" ? (<p className="sub-heading mb-5">Enjoy a wide range of delicious services</p>):(<p className="sub-heading mb-5">استمتع بخدماتنا المتنوعة</p>)}
           
          </div>
        </div>
        <div className="row">
          <div className="col-m mb-5d-6 col-lg-4" data-aos="fade-up">
            <div className="media feature-icon d-block text-center">
              <div className="icon">
                <img
                  className="service-icon"
                  href="#"
                  src={dinner}
                  alt="logo"
                />
              </div>
              <div className="media-body">
              {lang === "En" ? (<h3>Dine in</h3>):(<h3> في المطعم</h3>)}
              {lang === "En" ? (<p>You are always welcome to visit our restaurants to have an
                  exceptional dine in experience</p>):(<p> تفضل بزيارة أي من فروعنا واستمتع بتناول الطعام المعد بأمهر الأيادي  في صالة المطعم</p>)}
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 mb-5"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="media feature-icon d-block text-center">
              <div className="icon">
                <img
                  className="service-icon"
                  href="#"
                  src={delivery}
                  alt="logo"
                />
              </div>
              <div className="media-body">
              {lang === "En" ? (<h3>Delivery</h3>):(<h3> خدمة التوصيل</h3>)}
              {lang === "En" ? ( <p>
                  We are always one call away. <br />
                  Call us to make your order and let’s get the fire on those
                  grills.
                </p>):(<p> نحن دوماً على بعد اتصال واحد منك!</p>)}
              
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 mb-5"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="media feature-icon d-block text-center">
              <div className="icon">
                <img
                  className="service-icon"
                  href="#"
                  src={catering}
                  alt="logo"
                />
              </div>
              <div className="media-body">
              {lang === "En" ? (<h3>Catering</h3>):(<h3> تقديم الطعام</h3>)}
              {lang === "En" ? ( <p>
                  If you have an event that you’d like to celebrate, and you
                  want to spoil the guests with the Greater Amman Restaurant
                  deliciousness, <br />
                  we offer catering services throughout the Kingdom.
                </p>):(<p> إذا كنت تريد الاحتفال بحدث ما أو كنت تريد تقديم أفضل المأكولات من مطاعم عمّان الكبرى لضيوفك٬ يمكنك التنسيق معنا لنتمكن من إرسال طاقمنا وخدماتنا إلى أي منطقة حول المملكة</p>)}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

export default connect (mapStateToProps)(Services);
