import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import Footer from "./components/Home/Footer";
import Main from "./components/Main";
import Franchise from "./components/Home/Franchise";
import {connect} from "react-redux"

const App = ({lang})=> {
  const language = lang === "En" ? "ltr" : "rtl"
  return (
    <div className="main-wrap " id="section-home" style={{direction:language}}>
      
      <Switch>
        <Route path="/home" component={Main} />
        <Route path="/franchise" component={Franchise} />
        <Redirect to="/home" />
      </Switch>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

export default connect(mapStateToProps)(App);
