import {GET_LANGUAGE} from "../actions/actionTypes"

const initialState = {
    lang:localStorage.getItem("lang") ? localStorage.getItem("lang") :"En"
}

const reducer = (state = initialState,{type,payload}) => {
    switch (type) {
        case GET_LANGUAGE:
            localStorage.setItem("lang",payload)
            return {
                ...state,
                lang:payload
            }
            default:
                return state
    }
}

export default reducer