import React from "react";
import { Link } from "react-router-dom";
import logo2 from "../../assets/images/logo2.png";
import {connect} from "react-redux"

const Footer = ({lang}) => {
  return (
    <footer className="ftco-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-widget">
            {lang === "En" ? (<h3>Our Mission</h3>):(<h3 style={{float:"right"}}> مهمتنا</h3>)}
            </div>
            <div className="footer-widget">
            {lang === "En" ? ( <p
                style={{
                  fontSize: "15px",
                  color: "#fffce5",
                }}
              >
                It's the simple but ambitious mission of maintaining the
                identity of the Aleppo cuisine in all aspects, and growing our
                franchise to deliver the Mashawi goodness throughout the Middle
                East, Europe, and the world.
              </p>):(<p
                style={{
                  fontSize: "15px",
                  color: "#fffce5",
                  float:"right",
                  textAlign:"right"
                }}
              >
               مهمتنا بسيطة ولكن طموحة، فنحن نسعى للمحافظة على هوية المشاوي الحلبية الأصيلة بكل أشكالها وأنواعها، والنمو في السوق المحلّي والعالمي لنقوم بتقديم ما نبرع به في جميع أنحاء الشرق الأوسط وأوروبا والعالم أجمع.
              </p>)}
              
            </div>
            <div className="footer-widget">
            {lang === "En" ? (<h3>Join Us</h3>):(<h3 style={{float:"right"}}> انضم إلينا</h3>)}
            {lang === "En" ? ( <p
                style={{
                  fontSize: "15px",
                  color: "#fffce5",
                }}
              >
                 This is an exciting time to invest in and grow with a
                <strong> Greater Amman Restaurants®</strong> Kitchen franchise.
              </p>):(<p
                style={{
                  fontSize: "15px",
                  color: "#fffce5",
                  float:"right",
                  textAlign:"right"
                }}
            >هذا هو أفضل وقت للاستثمار في مطاعم عمّان الكبرى وافتتاح فروع جديدة في مختلف الدول. </p>)}
            </div>
            <div className="footer-widget mb-5 ">
              <Link to="/franchise">
              {lang === "En" ? (  <button className="btn btn-outline-white px-5 py-3 btn-sm">
                  We Franchise
                </button>):(  <button className="btn btn-outline-white px-5 py-3 btn-sm mb-5" style={{float:"right",letterSpacing:"inherit"}}> 
                  دعم الفروع
                </button>)}
              </Link>
            </div>
          </div>
          <div className="col-md-4 ml-5 mr-3">
            <div className=" row footer-widget">
            {lang === "En" ? (<h3>Contact Us</h3>):(<h3 style={{float:"right"}}> تواصل معنا</h3>)}
            </div>
            <div className=" row footer-widget">
              {lang === "En"? (
                <ul className="footer-list">
                <li className="sub-ul">
              Fifth Circle
                  <ul>
                    <li>Tel: +962 65923948</li>
                  </ul>
                </li>
                <li className="sub-ul">
                  Seventh Circle
                  <ul>
                    <li>Tel: +962 65818893</li>
                  </ul>
                </li>

                <li className="sub-ul">
                  Al-Muqabalayn
                  <ul>
                    <li>Mob: +962 790155222</li>
                  </ul>
                </li>
              </ul>
              ):(
                <ul className="footer-list" style={{float:"right",textAlign:"right"}}>
                <li className="sub-ul">
              فرع الدوار الخامس
                  <ul>
                    <li>هاتف: 0096265923948</li>
                  </ul>
                </li>
                <li className="sub-ul">
                  فرع الدوار السابع
                  <ul>
                    <li>هاتف: 0096265818893</li>
                  </ul>
                </li>

                <li className="sub-ul">
                  فرع المقابلين
                  <ul>
                    <li>موبايل: 0096279015522  </li>
                  </ul>
                </li>
              </ul>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className=" row footer-widget">
            {lang === "En" ? (<h3>Follow Us On</h3>):(<h3 style={{float:"right"}}> تابعنا على</h3>)}
            </div>
            <div className=" row footer-widget">
            {lang === "En" ? (  <ul className="list-unstyled social">
                <li>
                  <a
                    href="https://www.facebook.com/AmmanAlKubra"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ammanalkubra/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tripadvisor.com/Restaurant_Review-g293986-d4963283-Reviews-Amman_Al_Kubra-Amman_Amman_Governorate.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-tripadvisor"></i>
                  </a>
                </li>
              </ul>):(  <ul className="list-unstyled social" style={{float:"right",textAlign:"right"}}>
                <li>
                  <a
                    href="https://www.facebook.com/AmmanAlKubra"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ammanalkubra/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tripadvisor.com/Restaurant_Review-g293986-d4963283-Reviews-Amman_Al_Kubra-Amman_Amman_Governorate.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fab fa-tripadvisor"></i>
                  </a>
                </li>
              </ul>)}
            </div>
            <div className="footer-widget">
            {lang === "En" ? (<img src={logo2} alt="logo2" className="logo2" />):(<img src={logo2} alt="logo2" className="logo2" style={{float:"right"}} />)}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};


const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

export default connect (mapStateToProps) (Footer);
