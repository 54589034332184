import React ,{useEffect}from "react";
import {connect} from "react-redux"
import Fheader from "../Header/Fheader"
import emailjs from "emailjs-com"

const Franchise = ({lang}) => {
  useEffect(() => {
    let elmnt = document.getElementById("top");
    elmnt.scrollIntoView();
  }, []);

  const sendEmail= (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7jf5inf', 'template_franchise', e.target, 'user_AqCEdwtlp0As73jWrgRfF')
      .then((result) => {
        alert("Your Message has been Sent")
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <>
    <div id="top"></div>
    <Fheader />
    
    <div className="section" data-aos="fade-up" id="section-contact">
      <div className="container">
        <div className="row section-heading justify-content-center mb-5">
          <div className="col-md-8 text-center">
          {lang === "En" ? (<h2 className="heading mb-3">We Franchise</h2>):(<h2 className="heading mb-3">دعم الفروع</h2>)}
            
          </div>
          <dl>
          {lang === "En" ? (<dt>Greater Amman Restaurants®</dt>):(<dt style={{float:"right"}}>مطاعم عمّان الكبرى</dt>)}
          {lang === "En" ? (<dd>
              <p>
                The look & feel of Greater Amman Restaurants is achieved by
                keeping with our Middle Eastren heritage — using Simple Middle
                Eastren food court, Arabic Kitchen and nice Arabic setting. Once
                inside, our customers are welcomed with a smile and an inviting
                environment that represents warmth and the great Arabian
                hospitality. We adapt our facilities based on market needs that
                mainly depend on our customer’s needs.
              </p>
            </dd>):(<dd style={{float:"right",textAlign:"right"}}>
              <p>
              تتم المحافظة على هوية مطاعم عمّان الكبرى من خلال التمسّك بتراثنا العربي في الشرق الأوسط - ونحقق ذلك عبر المطابخ والصالات ذات الطابع الشامي البسيط والأجواء العربية الأصيلة. بمجرد الدخول إلى أحد فروعنا، يتم الترحيب بضيوفنا بابتسامة دافئة وبيئة جذابة تمثل كرم الضيافة العربية. ما يميّز مطاعمنا هو القدرة على التكيّف بناءً على احتياجات السوق الذي نتواجد به٬ والذي يعتمد بشكل أساسي على احتياجات ضيوفنا.
              </p>
            </dd>)}

            
            {lang === "En" ? (<dt>Franchise Support</dt>):(<dt style={{float:"right"}}>دعم الفروع</dt>)}
          {lang === "En" ? ( <dd>
              <p>
                We offer support in different ways through our team in jordan.
                We are here to support the growth of our true brand. And aim to
                deliver the authentic Mashawi experience throughout the world.
                We will support you in determining the best market development
                plan. How many restaurants and where? What type of venues? These
                questions are very important to build a successful brand that is
                true to Greater Amman Restaurant name. Our name is tied with
                strict procedures and operating standards. Your management team
                (including at least three managers per restaurant) will be flown
                to Jordan to be trained on the hands of our experts in the
                field.
              </p>
            </dd>):(<dd style={{float:"right",textAlign:"right"}}>
              <p>
              نوفر الدعم بطرق مختلفة من خلال فريقنا في الأردن. نحن هنا لدعم نمو علامتنا التجارية٬ التي تهدف لتقديم تجربة المشاوي الأصيلة ونشرها في جميع أنحاء العالم. سندعمك تحديداً بإيجاد أفضل خطة لتطوير السوق. عدد المطاعم ومواقعها. كل هذه المعلومات مهمة للغاية لبناء علامة تجارية ناجحة تتوافق مع اسم مطاعم عمّان الكبرى. يرتبط اسمنا بإجراءات ومعايير تشغيلية ثابتة وصارمة. عند التوسّع معنا٬ سنقوم بتدريب طاقم الإدارة الخاص بك والمكوّن من - ثلاثة مدراء على الأقل للمطعم الواحد في الأردن على أيدي خبرائنا.              </p>
            </dd>)}
          </dl>

          {lang === "En" ? ( <h3 className="heading mb-3" style={{ color: "#660000" }}>
            Request Information
          </h3>):( <h3 className="heading mb-3" style={{ color: "#660000" }}>
          معلومات إرسال طلب 
          </h3>)}

          {lang === "En" ? (<p style={{ fontSize: "large",textAlign:"center" }}>
            This is an exciting time to invest in and grow with a
            <strong> Greater Amman Restaurants®</strong> Kitchen franchise.
            Please fill the details below, and our Franchise Manager will
            contact you within 24 hours of your form submission.
          </p>):(<p style={{float:"right",textAlign:"center",fontSize: "large"}}>هذا هو أفضل وقت للاستثمار في مطاعم عمّان الكبرى وافتتاح فروع جديدة في مختلف الدول. إذا كنت مهتماً بمعرفة كل المعلومات اللازمة٬ ما عليك سوى تعبئة المعلومات المطلوبة أدناه ليتمكّن مدير الفروع من التواصل معك خلال 24 ساعة من تعبئة الطلب.</p>)}

          
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10 p-5 form-wrap">
            <form action="#" style={{fontFamily:"El Messiri, sans-serif"}} onSubmit={sendEmail}>
              <div className="row mb-4">
                <div className="form-group col-md-4">
                {lang === "En" ? (<label htmlFor="name" className="label">
                    Name
                  </label>):(<label htmlFor="name" className="label" style={{float:"right"}}>
                    الاسم
                  </label>)}
                  
                  <div className="form-field-icon-wrap">
                    <span className="icon ion-android-person"></span>
                    <input type="text" className="form-control" id="name" name="name" />
                  </div>
                </div>
                <div className="form-group col-md-4">
                {lang === "En" ? ( <label htmlFor="email" className="label">
                    Email
                  </label>):( <label htmlFor="email" className="label" style={{float:"right"}}>
                    البريد الإلكتروني
                  </label>)}
                 
                  <div className="form-field-icon-wrap">
                    <span className="icon ion-email"></span>
                    <input type="email" className="form-control" id="email" name="email" />
                  </div>
                </div>
                <div className="form-group col-md-4">
                {lang === "En" ? ( <label htmlFor="phone" className="label">
                    Phone
                  </label>):( <label htmlFor="phone" className="label" style={{float:"right"}}>
                    هاتف/موبايل
                  </label>)}
                  
                  <div className="form-field-icon-wrap">
                    <span className="icon ion-android-call"></span>
                    <input type="text" className="form-control" id="phone" name="phone" />
                  </div>
                </div>

                <div className="form-group col-md-12">
                {lang === "En" ? ( <label htmlFor="message" className="label">
                    Message
                  </label>):( <label htmlFor="message" className="label" style={{float:"right"}}>
                    الرسالة
                  </label>)}
                  
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    className="form-control"
                  ></textarea>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <input
                    type="submit"
                    className="btn btn-primary btn-outline-primary btn-block"
                    value={lang==="En" ? "Send Message":"أرسل"}
                    style={{
                      backgroundColor: "#660000",
                      color: "#fffce5",
                      border: "none",
                      fontWeight:"bolder",
                      letterSpacing:"inherit"
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});

export default connect (mapStateToProps) (Franchise);
