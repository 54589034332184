import React from "react";
import MenuCategory from "./MenuCategory";
import {connect} from "react-redux"

const Menu = ({lang}) => {
  return (
    <div className="section bg-light " id="menu" data-aos="fade-up">
      <div className="row section-heading justify-content-center mb-5">
        <div className="col-md-8 text-center">
          <h2 className="heading mb-3">{lang==="En"? "Menu" : "قائمة الطعام"}</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <MenuCategory />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});
export default connect(mapStateToProps)(Menu);
