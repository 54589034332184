import React,{Component} from "react";
import Cover from "./Header/Cover";
import History from "./Home/History";
import About from "./Home/About";
import Services from "./Home/Services";
import {getItems,getCategories} from '../redux/actions'
import Menu from "./Menu/Menu";
import Locations from "./Home/Locations";
import { connect } from "react-redux";
class Main extends Component{
  componentDidMount(){
    this.props.getItems()
    this.props.getCategories()
  }
  render(){
  return (
    <>
      <Cover />
      <History />
      <About />
      <Menu/>
      <Services />
      <Locations/>
    </>
  );
};
};


const mapDispatchToProps = {getItems,getCategories}

export default connect(null,mapDispatchToProps)(Main);
