import React from "react";
import Item from "./Item";
import { connect } from "react-redux";

const MenuItem = ({ category, items }) => {
  const catitems = items.filter(
    (item) => item.category.nameEn === category.nameEn
  );
  const itemsList = catitems.map((item) => (
    <Item item={item} key={item.nameEn} />
  ));

  var test = "";
  if (category.nameEn === "Salads") {
    test = "tab-pane fade show active";
  } else {
    test = "tab-pane fade ";
  }
  return (
    <div
      className={test}
      id={`${category.nameEn.toLowerCase().replace(/\s+/g, "")}`}
      role="tabpanel"
      aria-labelledby={`${category.nameEn
        .toLowerCase()
        .replace(/\s+/g, "")}-tab`}
    >
      <div className="row">{itemsList}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  items: state.itemState.items,
});

export default connect(mapStateToProps)(MenuItem);
