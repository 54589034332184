import React from "react";
import { connect } from "react-redux";

const History = ({ history,lang }) => {
  // const title1 = history[0] ? history[0].titleEn : "";
  const imageURLH0 =
    "https://ammanrestapi.cloud" + String(history[0] ? history[0].image : "");
  const imageURLH1 =
    "https://ammanrestapi.cloud" + String(history[1] ? history[1].image : "");
  const imageURLH2 =
    "https://ammanrestapi.cloud" + String(history[2] ? history[2].image : "");
  return (
    <div className="section" data-aos="fade-up">
      <div className="container">
        <div className="row section-heading justify-content-center mb-5">
          <div className="col-md-8 text-center">
            <h2 className="heading mb-3">
            {lang === "En" ? "Our Story" :"قصتنا"}
            
            </h2>
            <p className="sub-heading mb-5">
            {lang === "En" ? "What is now considered to be one of the best known Mashawi (Grills) Restaurants in Amman has a history that pre-exists therestaurant itself." :"تُعرف اليوم مطاعم عمّان الكبرى بكونها إحدى أشهر مطاعم المشاوي في الأردن. ويعود تاريخ هذا المطعم إلى ما قبل تواجد وافتتاح المطعم بالأساس."}
              
            </p>
          </div>
        </div>
        <div className="row">
          <div className="ftco-46">
            {/* 111111111 */}
            <div className="ftco-46-row d-flex flex-column flex-lg-row">
              <div className="ftco-46-image">
                <img className="story" src={imageURLH0} alt="story" />
              </div>
              <div className="ftco-46-text ">
                <h4 className="ftco-46-heading">
                  {history[0] ? lang==="En" ?history[0].titleEn: history[0].titleAr : ""}
                </h4>
                <p className="mb-5">
                  {history[0] ? lang==="En" ?history[0].descriptionEn:history[0].descriptionAr : ""}
                </p>
              </div>
              <div className="ftco-46-image">
                <img className="story" src={imageURLH2} alt="story" />
              </div>
            </div>
            {/* ///11111111////// */}
            <div className="ftco-46-row d-flex flex-column flex-lg-row">
              <div className="ftco-46-text ">
                <h4 className="ftco-46-heading">
                {history[1] ? lang==="En" ?history[1].titleEn: history[1].titleAr : ""}
                </h4>
                <p className="mb-5">
                {history[1] ? lang==="En" ?history[1].descriptionEn:history[1].descriptionAr : ""}
                </p>
              </div>
              <div className="ftco-46-image">
                <img className="story" src={imageURLH1} alt="story" />
              </div>
              <div className="ftco-46-text ">
                <h4 className="ftco-46-heading">
                {history[2] ? lang==="En" ?history[2].titleEn: history[2].titleAr : ""}
                </h4>
                <p className="mb-5">
                {history[2] ? lang==="En" ?history[2].descriptionEn:history[2].descriptionAr : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  history: state.historyState.history,
  lang: state.languageState.lang,
});


export default connect(mapStateToProps)(History);
