import { combineReducers } from "redux";

import categoryReducer from "./category";
import itemReducer from "./item";
import historyReducer from "./history";
import languageReducer from "./language";

export default combineReducers({
  categoryState: categoryReducer,
  itemState: itemReducer,
  historyState: historyReducer,
  languageState:languageReducer
});
