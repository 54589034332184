import React from "react";
import { connect } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Item = ({ item ,lang}) => {
  const imageURL = "https://ammanrestapi.cloud/" + String(item.image);
  return (
    <div className="col-md-4 my-1">
      <div className="card-deck h-100" style={{ height: "95%" }}>
        <div className="card">
          <div className="text order-1 mb-3">
            <LazyLoadImage
              className="cardimg rounded"
              src={imageURL}
              alt="img"
              style={{ height: "214px", width: "100%" }}
            />
            <div className="card-body text-center">
              <h5>{lang==="En"?item.nameEn:item.nameAr}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  lang: state.languageState.lang,
});
export default connect(mapStateToProps)(Item);
